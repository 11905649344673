<template>
  <b-card>
    <b-overlay
      :show="isRowDeleted"
      rounded="sm"
    >
      <!-- table -->
      <vue-good-table
        v-if="rows"
        ref="AffHomeTable"
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: false,
          placeholder: 'Search this table',
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'created_at', type: 'desc'}
        }"
        :pagination-options="{
          enabled: true,
          perPage: 10,
          perPageDropdown: [10]
        }"
        class="mt-3"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: id -->
          <span v-if="props.column.field === 'id'">
            <router-link :to="`/news/detail/${props.row.id}`">{{ props.row.id }}</router-link>
          </span>

          <!-- Column: title -->
          <span v-else-if="props.column.field === 'title'">
            <router-link :to="`/news/detail/${props.row.id}`">
              {{ props.row.title }}
            </router-link>
            <b-badge
              v-if="props.row.is_new"
              class="ml-1 border rounded-pill text-white"
              size="sm"
              variant="success"
            >NEW</b-badge>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="props.row.published ? 'light-success' : 'light-danger'"
            >{{ props.row.published ? 'Published' : 'Not published' }}</b-badge>
          </span>

          <span v-else-if="props.column.field === 'publish_date'">
            {{ (props.row.publish_date)?(formatDate(props.row.publish_date, 'publish')):('_') }}
          </span>

          <span v-else-if="props.column.field === 'schedule'">
            <b-badge
              v-if="props.row.publish_date"
              variant="success"
              class="text-white"
            >ON</b-badge>
            <b-badge
              v-else
              variant="danger"
              class="text-white"
            >OFF</b-badge>
          </span>

          <!-- Column: action -->
          <span
            v-else-if="props.column.field === 'action'"
            class="d-flex justify-content-around"
          >
            <router-link :to="`/news/edit/${props.row.id}`">
              <feather-icon
                class="text-warning"
                icon="EditIcon"
                size="21"
              />
            </router-link>

            <feather-icon
              class="text-danger"
              style="cursor: pointer"
              icon="XCircleIcon"
              size="21"
              @click="deleteNews(props.row.id, props.row.title)"
            />

          </span>

          <!-- Column: created_at -->
          <span v-else-if="props.column.field === 'created_at'">
            {{ formatDate(props.row.created_at) }}
          </span>

          <span v-else-if="props.column.field === 'tag'">
            <div v-if="props.row.tag.length > 1 && typeof props.row.tag === 'object'">
              <!-- eslint-disable -->
              <b-badge v-for="tag in props.row.tag" :key="Math.random()" class="mx-1 align-items-center" :variant="getVariantTag(tag)">{{ tag }}</b-badge>
            </div>
            <div v-else>
              <b-badge :variant="getVariantTag(props.row.tag)">{{ props.row.tag }}</b-badge>
            </div>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-overlay>

  </b-card>
</template>

<script>
/* eslint-disable */
import { BCard, BOverlay, BBadge} from 'bootstrap-vue' // eslint-disable-line
import { VueGoodTable } from 'vue-good-table'
import axios from '@/libs/axios' // eslint-disable-line
import moment from 'moment'

export default {
  components: {
    BCard,
    BBadge,
    VueGoodTable,
    BOverlay,
  },

  data() {
    return {
      columns: [
        {
          label: 'News ID',
          field: 'id',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Tag',
          field: 'tag',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Title',
          field: 'title',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Schedule',
          field: 'schedule',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Schedule Date',
          field: 'publish_date',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
          sortFn: this.sortFnPublishDate,
        },
        {
          label: 'Date Issue',
          field: 'created_at',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: true,
          sortFn: this.sortFnDate,
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      rows: [],
      isRowDeleted: false,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  created() {
    this.fetchRecord()
  },

  methods: {
    getVariantTag(tag) {
      let variant = 'light-secondary'
      if (tag === 'Product Updates') variant = 'light-warning'
      else if (tag === 'Newsletter') variant = 'light-success'
      return variant
    },

    async fetchRecord() {
      const records = await axios.get('/api/news/list')
      this.rows = records.data
      this.rows = this.rows.map(e => {
        if (e.tag.includes(',')) e.tag = e.tag.split(",");
        return e;
      });
    },

    formatDate(datum, type = 'normal') {
      if(type == 'publish') return moment(new Date(datum)).format('DD MMM YYYY | HH:mm')
      return moment(new Date(datum)).format('DD MMM YYYY')
    },

    sortFnDate(x, y, col, rowX, rowY) { // eslint-disable-line
      // get x
      let value1 = 0
      value1 = Date.parse(this.filterFormatDate(rowX.created_at))

      // get y
      let value2 = 0
      value2 = Date.parse(this.filterFormatDate(rowY.created_at))

      // test values
        return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },

    deleteNews(id, title) {
      this.confirmationAlert(`Are you sure you want to delete this news titled "${title}"?`)
        .then(delres => {
          this.isRowDeleted = true
          if (delres.isConfirmed) {
            const uri = `/api/news/destroy/${id}`
            axios.delete(uri)
              .then(res => { // eslint-disable-line
                this.fetchRecord();
                this.isRowDeleted = false
              })
              .catch(error => { console.log(error) })
          }
          else this.isRowDeleted = false
        })
    },
  },
}
</script>
    <style lang="scss" >
      @import '@core/scss/vue/libs/vue-good-table.scss';
    </style>
    <style>
    .activation-btn {
      width: 100%;
      max-width: 16rem;
    }
    .slot {
      padding: 0.5rem;
      justify-content: space-between;
    }
    .slot span{
      margin-right:5px; margin-top:5px;
      font-size: 11px;
      font-weight: bold;
    }
    .mt-3 table tr th{font-size: 11px;}
    .mt-3 table tr td{font-size: 12px;}
    .mt-3 { margin-top: 0px !important;}
    .stat-picker{
      margin-right: 0.3rem;
      margin-bottom: -10px !important;
    }
    .btn-action{
      margin-top: 5px !important;
      float: right;
    }
    </style>
